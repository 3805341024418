<template>
  <div class="mt-4 max-w-screen-md">
    <loader :isLoading="isLoading || isSubmitting" />
    <lf-card class="p-6">
      <access-control :roles="[SUPER_ADMIN, ADMIN]">
        <lf-switch
          class="pb-6"
          name="enable_test_mode"
          v-model="enableTestMode"
          @toggleChanged="handleToggleTestMode"
          role="testModeToggle"
        >
          <lf-h3>{{ $t("ORGANIZATION.TEST_MODE.TITLE") }}</lf-h3>
          <span class="mt-2">
            {{ $t("ORGANIZATION.TEST_MODE.DESCRIPTION") }}
          </span>
        </lf-switch>
        <div class="flex justify-end items-center min-w-full border-t pt-6">
          <primary-button
            type="button"
            @click="handleTestModeChanges"
            :disabled="isSubmitting"
            role="saveTestMode"
          >
            {{ $t("COMMON.SAVE_CHANGES") }}
          </primary-button>
        </div>
      </access-control>
    </lf-card>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useStore } from "vuex";
const { getters, dispatch } = useStore();
import { useAuth } from "@/hooks/auth";
import { SUPER_ADMIN, ADMIN } from "@/helpers/constants";
import type { IClient } from "@/models/clients";
const isLoading = ref(false);
const isSubmitting = ref(false);
const activeClient = computed<IClient>(() => getters["clients/active"]);
const enableTestMode = ref(activeClient.value.in_test_mode || false);
const { isSuperAdmin, isAdmin } = useAuth();
const handleToggleTestMode = async (val: boolean) => {
  if (!isAdmin && !isSuperAdmin) {
    return;
  }
  enableTestMode.value = val;
};
const handleTestModeChanges = async () => {
  if (!isAdmin && !isSuperAdmin) {
    return;
  }
  isSubmitting.value = true;
  await dispatch("clients/updateClientTestMode", {
    test_mode: enableTestMode.value
  });
  isSubmitting.value = false;
};
</script>
